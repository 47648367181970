<template>
  <div class="container xl">
    
    <OrderDetail :id="$route.params.id"/>
  </div>
</template>

<script>
import OrderDetail from '@/components/orders/OrderDetail';

export default {
  name: 'OrderView',
  components: {OrderDetail},
  mounted() {
    
  }
}
</script>

<style>

</style>