<template>
  <div class="loading-overlay" v-if="loading">
    <div class="loader"></div>
  </div>

  <div class="order-detail" v-if="!loading">
    <div id="bookingCollection" class="booking-form-section booking-collection">
      <div class="content">
        <div class="cols">
          <div class="left">
            <div id="booking_collectionAddress" class="box collection-address">
              <div class="inner-wrapper">
                <div class="inner-title-wrapper">
                  <h5 class="title">Collection Address</h5>
                </div>

                <div class="the-values">
                  <div class="cols">
                    <div class="col contact-details">
                      <div class="inner-wrapper-1">
                        <div class="title">
                          {{ order.collectionAddress.contactName }}
                        </div>
                        <div>{{ order.collectionAddress.contactMobile }}</div>
                        <div>{{ order.collectionAddress.contactEmail }}</div>
                      </div>
                    </div>
                    <div class="col address">
                      <div class="inner-wrapper-1">
                        <div class="title">
                          {{ order.collectionAddress.name }}
                        </div>
                        <div>
                          {{ order.collectionAddress.primary }}
                          {{ order.collectionAddress.secondary }}
                          {{ order.collectionAddress.landmark }},
                          {{ order.collectionAddress.cityName }},
                          {{ order.collectionAddress.zipCode }},
                          {{ order.collectionAddress.countryName }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="right">
            <div id="booking_collectionTime" class="box collection-time">
              <div class="inner-wrapper">
                <div class="inner-title-wrapper">
                  <h5 class="title">Collection Time</h5>
                </div>

                <div class="the-values">
                  <div class="date-time">
                    <div class="date">
                      <div class="date-month">
                        {{ collectionFormattedDate.monthDate }}
                      </div>
                      <div class="year">
                        {{ "," + collectionFormattedDate.year }}
                      </div>
                    </div>
                    <div class="time">{{ order.collectionTime }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      id="bookingDestination"
      class="booking-form-section booking-destination"
    >
      <div class="content">
        <div id="booking_destinationAddress" class="box destination-address">
          <div class="inner-wrapper">
            <div class="inner-title-wrapper">
              <h5 class="title">Destination Address</h5>
            </div>

            <div class="the-values">
              <div class="cols">
                <div class="col contact-details">
                  <div class="inner-wrapper-1">
                    <div class="title">
                      {{ order.destinationAddress.contactName }}
                    </div>
                    <div>{{ order.destinationAddress.contactMobile }}</div>
                    <div>{{ order.destinationAddress.contactEmail }}</div>
                  </div>
                </div>
                <div class="col address">
                  <div class="inner-wrapper-1">
                    <div class="title">{{ order.destinationAddress.name }}</div>
                    <div>
                      {{ order.destinationAddress.primary }}
                      {{ order.destinationAddress.secondary }}
                      {{ order.destinationAddress.landmark }},
                      {{ order.destinationAddress.cityName }},
                      {{ order.destinationAddress.zipCode }},
                      {{ order.destinationAddress.countryName }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="bookingPackages" class="booking-form-section booking-packages">
      <div class="content">
        <div id="booking_packages" class="box packages">
          <div class="inner-wrapper">
            <div class="title-n-list">
              <div class="inner-title-wrapper">
                <h5 class="title">Packages</h5>
              </div>

              <div class="package-list">
                <div class="cols table-head">
                  <div class="col type">Type</div>
                  <div class="col dimensions">Dimensions</div>
                  <div class="col weight">Weight</div>
                  <div class="col value">Value</div>
                </div>
                <div
                  class="cols package"
                  v-for="(pkg, key) in order.packages"
                  :key="key"
                >
                  <div class="col type">
                    <div class="name-n-icon">
                      <div
                        class="icon"
                        :style="{
                          'background-image':
                            pkg.package.iconImage != null
                              ? 'url(' + pkg.package.iconImage + ')'
                              : '',
                        }"
                      ></div>
                      <div class="name">{{ pkg.package.name }}</div>
                    </div>
                  </div>
                  <div class="col dimensions">
                    {{ pkg.length }} x {{ pkg.width }} x {{ pkg.height }} CM
                  </div>
                  <div class="col weight">{{ pkg.weight }} KG</div>
                  <div class="col value">{{ pkg.value }} AED</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      id="bookingDeliveryService"
      class="booking-form-section booking-delivery-service"
    >
      <div class="content">
        <div id="booking_deliveryService" class="box delivery-service">
          <div class="inner-wrapper">
            <div class="inner-title-wrapper">
              <h5 class="title">Delivery Service</h5>
            </div>

            <div class="the-values">
              <div class="delivery-services">
                <div class="service">
                  <div
                    class="logo"
                    :style="{
                      'background-image':
                        order.selectedCompany.logoImage != null
                          ? 'url(' + order.selectedCompany.logoImage + ')'
                          : '',
                    }"
                  ></div>
                  <div class="name">{{ order.selectedCompany.name }}</div>
                  <div class="price">{{ order.totalPrice }}AED</div>
                </div>
              </div>

              <div class="box payments" v-if="order.status=='Draft'">
                <a :href="order.paymentURL" class="payBtn">
                  Pay AED {{ order.totalPrice }}</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import flatpickr from "flatpickr";

export default {
  name: "OrderDetail",
  props: ["id"],
  data() {
    return {
      order: {
        collectionAddress: {},
        destinationAddress: {},
        packages: [],
        selectedCompany: {},
      },
      loading: false,
      collectionFormattedDate: {
        monthDate: null,
        year: null,
      },
    };
  },
  mounted() {
    this.loading = true;

    this.$store.dispatch("fetchOrder", { orderId: this.id }).then((data) => {
      this.order = data.order;
      let date = flatpickr.parseDate(this.order.collectionDate, "Y-m-d");
      this.collectionFormattedDate.monthDate = flatpickr.formatDate(
        date,
        "F j"
      );
      this.collectionFormattedDate.year = flatpickr.formatDate(date, "Y");
      this.loading = false;
    });
  },
};
</script>

<style lang="scss">
@import "@/scss/partials/order-detail.scss";
.payBtn {
  height: 42px;
  border-radius: 50px;
  box-shadow: 0 2px 20px 0 rgba(148, 102, 0, 0.47);
  background-color: #e79f00;
  font-size: 17px;
  line-height: 44px;
  font-weight: 600;
  text-align: center;
  color: #ffffff;
  border: 0px none;
  min-width: 50px;
  padding: 0px 30px;
  float: right;
}
</style>
